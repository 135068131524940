import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchWaybillList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post('/waybill/list', params)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    reviewSubmitDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/waybill/confirm-by-trade?access_token=${params.access_token}&status=${params.status}&note=${params.note}&trades=${params.trades}&pagsmile_notice=${params.pagsmileNotice}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    trackingUpdating(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/waybill/update-tracking?access_token=${params.access_token}&trades=${params.trades}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    downloadWaybill(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/waybill/download?access_token=${params.access_token}`, params.data)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // download log list
    downloadWaybillList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/waybill/download-list?access_token=${params.access_token}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    // batch review
    batchReviewSubmitDetail(ctx, params) {
      const header = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      return new Promise((resolve, reject) => {
        axios.post(`/waybill/upload?access_token=${params.access_token}`, params.files, header)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    uploadHistoryList(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/waybill/upload-history?access_token=${params.access_token}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchAppListAll(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`app/list-all?access_token=${params.access_token}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
    fetchWaybillDetail(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/waybill/detail?access_token=${params.access_token}&trade_no=${params.trade_no}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },

    batchReviewDownloadTemplate(ctx, params) {
      return new Promise((resolve, reject) => {
        axios.post(`/waybill/download-template?access_token=${params.access_token}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => { reject(error) })
      })
    },
  },
}
