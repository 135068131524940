import store from '@/store'
import waybillStoreModule from '@/pagsmile/waybillStoreModule'
import commonStoreModule from '../commonStoreModule'

export default function waybillJs() {
  const WAYBILL_STORE_MODULE_NAME = 'waybill'
  const COMMON_STORE_MODULE_NAME = 'common'

  // Register module
  if (!store.hasModule(WAYBILL_STORE_MODULE_NAME)) store.registerModule(WAYBILL_STORE_MODULE_NAME, waybillStoreModule)
  if (!store.hasModule(COMMON_STORE_MODULE_NAME)) store.registerModule(COMMON_STORE_MODULE_NAME, commonStoreModule)

  const fetchWaybillList = (params, success, fail) => {
    store
      .dispatch('waybill/fetchWaybillList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const reviewSubmitDetail = (params, success, fail) => {
    store
      .dispatch('waybill/reviewSubmitDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  // trackingUpdating
  const trackingUpdating = (params, success, fail) => {
    store
      .dispatch('waybill/trackingUpdating', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  // 下载
  const downloadWaybill = (params, success, fail) => {
    store
      .dispatch('waybill/downloadWaybill', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const downloadWaybillList = (params, success, fail) => {
    store
      .dispatch('waybill/downloadWaybillList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  // 上传
  const batchReviewSubmitDetail = (params, success, fail) => {
    store
      .dispatch('waybill/batchReviewSubmitDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }
  // 上传历史
  const uploadHistoryList = (params, success, fail) => {
    store
      .dispatch('waybill/uploadHistoryList', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      })
      .catch(() => { fail('error') })
  }

  const fetchCommonOptions = (success, fail) => {
    store
      .dispatch('common/fetchCommonOptions')
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }
  // apptype merchant App
  const fetchAppListAll = (success, fail, params) => {
    store
      .dispatch('waybill/fetchAppListAll', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }

  const fetchWaybillDetail = (success, fail, params) => {
    store
      .dispatch('waybill/fetchWaybillDetail', params)
      .then(response => {
        const { code, msg, data } = response.data

        if (code === '10000') {
          success(data)
        } else {
          fail(msg)
        }
      }).catch(() => {
        fail('error')
      })
  }

  const batchReviewDownloadTemplate = (success, fail, params) => {
    store
      .dispatch('waybill/batchReviewDownloadTemplate', params)
      .then(response => {
        success(response.data)
      }).catch(() => {
        fail('error')
      })
  }

  return {
    fetchWaybillList,
    reviewSubmitDetail,
    trackingUpdating,
    downloadWaybill,
    downloadWaybillList,
    batchReviewSubmitDetail,
    uploadHistoryList,
    fetchCommonOptions,
    fetchAppListAll,
    fetchWaybillDetail,
    batchReviewDownloadTemplate,
  }
}
